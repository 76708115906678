$brand-color:      #ffa048;
$grey-color:       #034fdbfa;

@import "minima";

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

h5 {
    font-size: $base-font-size;
}
